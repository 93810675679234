<template>
  <div class="hello">
    <div class="header">
      <div
        class="flex"
        style="
          justify-content: center;
          align-items: center;
          width: 1200px;
          margin: 0 auto;
        "
      >
        <div style="font-size: 35px; color: #000000; line-height: 80px">
          公众服务助手
        </div>
        <div class="flexJusp" style="width: 510px; margin: 0 150px 0 260px">
          <div
            @click="shouye"
            :class="type == 0 ? 'xuan' : 'weixuan'"
            style="cursor: pointer"
          >
            首页
          </div>
          <div
            @click="hangye"
            :class="type == 1 ? 'xuan' : 'weixuan'"
            style="cursor: pointer"
          >
            行业方案
          </div>
          <div
            @click="qiehuan(2)"
            :class="type == 2 ? 'xuan' : 'weixuan'"
            style="cursor: pointer"
          >
            开通服务
          </div>
          <div
            @click="yingyong"
            :class="type == 3 ? 'xuan' : 'weixuan'"
            style="cursor: pointer"
          >
            应用市场
          </div>
          <div
            @click="lianxi"
            :class="type == 4 ? 'xuan' : 'weixuan'"
            style="cursor: pointer"
          >
            联系我们
          </div>
        </div>
        <div @click="denglu" class="buttons" style="cursor: pointer">登录</div>
      </div>
    </div>
    <div style="height: 80px"></div>
    <img
      style="width: 100%; height: 537px; display: block; object-fit: cover"
      src="@/assets/images/bn01.jpg"
    />
    <div class="flexJusp" style="width: 1200px; margin: 22px auto 60px">
      <div v-for="(item, index) in list" :key="index">
        <div
          v-if="item.type == 1"
          style="
            width: 288px;
            border-radius: 15px;
            border: 1px solid #dcdcdc;
            text-align: center;
            padding-bottom: 20px;
          "
        >
          <img
            style="
              width: auto;
              height: 66px;
              display: block;
              margin: 34px auto 9px;
            "
            :src="url + item.image"
          />
          <div style="font-size: 21px; color: #343434">{{ item.name }}</div>
          <div
            style="
              border-top: 1px solid #dcdcdc;
              width: 220px;
              padding: 30px 0 36px;
              margin: auto;
              font-size: 16px;
              margin-top: 30px;
            "
          >
            <div
              class="flexJusp"
              style="align-items: center; margin-top: 14px"
              v-for="(items, indexs) in item.content"
              :key="indexs"
            >
              <div>·{{ items }}</div>
              <img
                style="width: 16px; height: 16px; display: block"
                src="@/assets/images/dui_1.png"
              />
            </div>
          </div>
          <div class="buttonx" @click="open(item)">立即开通</div>
        </div>
        <div
          v-else
          style="
            width: 288px;
            padding-bottom: 20px;
            border-radius: 15px;
            border: 1px solid #484848;
            text-align: center;
            background: linear-gradient(180deg, #484848 0%, #616060 100%);
            color: #fff;
          "
        >
          <img
            style="
              width: 66px;
              height: 66px;
              display: block;
              margin: 34px auto 9px;
              border-radius: 50%;
            "
            :src="url + item.image"
          />
          <div style="font-size: 21px">{{ item.name }}</div>
          <div
            style="
              border-top: 1px solid #dcdcdc;
              width: 220px;
              padding: 30px 0 36px;
              margin: auto;
              font-size: 16px;
              margin-top: 30px;
            "
          >
            <div
              class="flexJusp"
              style="align-items: center; margin-top: 14px"
              v-for="(items, indexs) in item.content"
              :key="indexs"
            >
              <div>·{{ items }}</div>
              <img
                style="width: 16px; height: 16px; display: block"
                src="@/assets/images/dui_2.png"
              />
            </div>
          </div>
          <div class="buttonss" @click="open(item)">联系客服</div>
        </div>
      </div>
    </div>
    <div style="width: 1200px; margin: auto">
      <img
        v-if="types == 0"
        style="width: 1200px; height: 740px; display: block"
        src="@/assets/images/hb_1.png"
      />
      <img
        v-if="types == 1"
        style="width: 1200px; height: 740px; display: block"
        src="@/assets/images/hb_2.png"
      />
      <img
        v-if="types == 2"
        style="width: 1200px; height: 740px; display: block"
        src="@/assets/images/hb_3.png"
      />
      <img
        v-if="types == 3"
        style="width: 1200px; height: 740px; display: block"
        src="@/assets/images/hb_4.png"
      />
      <img
        v-if="types == 4"
        style="width: 1200px; height: 740px; display: block"
        src="@/assets/images/hb_5.png"
      />
      <img
        v-if="types == 5"
        style="width: 1200px; height: 740px; display: block"
        src="@/assets/images/hb_6.png"
      />
      <img
        v-if="types == 6"
        style="width: 1200px; height: 740px; display: block"
        src="@/assets/images/hb_7.png"
      />
      <img
        v-if="types == 7"
        style="width: 1200px; height: 740px; display: block"
        src="@/assets/images/hb_8.png"
      />
      <img
        v-if="types == 8"
        style="width: 1200px; height: 740px; display: block"
        src="@/assets/images/hb_9.png"
      />
      <img
        v-if="types == 9"
        style="width: 1200px; height: 740px; display: block"
        src="@/assets/images/hb_10.png"
      />
      <div class="flexJusp" style="margin: 40px 0 20px">
        <div
          @click="qiehuans(0)"
          :class="types == 0 ? 'buttonxuan' : 'buttonwei'"
        >
          分销商城
        </div>
        <div
          @click="qiehuans(1)"
          :class="types == 1 ? 'buttonxuan' : 'buttonwei'"
        >
          直播商城
        </div>
        <div
          @click="qiehuans(2)"
          :class="types == 2 ? 'buttonxuan' : 'buttonwei'"
        >
          零售批发
        </div>
        <div
          @click="qiehuans(3)"
          :class="types == 3 ? 'buttonxuan' : 'buttonwei'"
        >
          酒店预订
        </div>
        <div
          @click="qiehuans(4)"
          :class="types == 4 ? 'buttonxuan' : 'buttonwei'"
        >
          知识付费
        </div>
      </div>
      <div class="flexJusp">
        <div
          @click="qiehuans(5)"
          :class="types == 5 ? 'buttonxuan' : 'buttonwei'"
        >
          礼品积分
        </div>
        <div
          @click="qiehuans(6)"
          :class="types == 6 ? 'buttonxuan' : 'buttonwei'"
        >
          短视频
        </div>
        <div
          @click="qiehuans(7)"
          :class="types == 7 ? 'buttonxuan' : 'buttonwei'"
        >
          多门店
        </div>
        <div
          @click="qiehuans(8)"
          :class="types == 8 ? 'buttonxuan' : 'buttonwei'"
        >
          多商户
        </div>
        <div
          @click="qiehuans(9)"
          :class="types == 9 ? 'buttonxuan' : 'buttonwei'"
        >
          视频号
        </div>
      </div>
    </div>
    <div
      class="footer-content"
      style="
        width: 1200px;
        margin: 20px auto 0;
        border-top: 1px solid #666;
        border-bottom: 1px solid #666;
      "
    >
      <div class="content-nav">
        <div class="nav-item">
          <div
            class="nav-item-title"
            style="color: #333333; margin-bottom: 18px"
          >
            数字化管理
          </div>
          <ul>
            <li style="color: #757575; margin-bottom: 18px">
              <a href="https://www.yinzhih.com//?pid=23&amp;tid=6"
                >数字化商城</a
              >
            </li>
            <li style="color: #757575; margin-bottom: 18px">
              <a href="https://www.yinzhih.com//?pid=16&amp;tid=6"
                >视频号服务</a
              >
            </li>
          </ul>
        </div>
        <div class="nav-item">
          <div
            class="nav-item-title"
            style="color: #333333; margin-bottom: 18px"
          >
            关于我们
          </div>
          <ul>
            <li style="color: #757575; margin-bottom: 18px">
              <a href="https://www.yinzhih.com//?pid=18&amp;tid=6">公司简介</a>
            </li>
            <li style="color: #757575; margin-bottom: 18px">
              <a href="https://www.yinzhih.com//?pid=18&amp;tid=6">企业价值</a>
            </li>
            <li style="color: #757575; margin-bottom: 18px">
              <a href="https://www.yinzhih.com//?pid=18&amp;tid=6">企业愿景</a>
            </li>
            <li style="color: #757575; margin-bottom: 18px">
              <a href="https://www.yinzhih.com//?pid=18&amp;tid=6">企业文化</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="content-qrcode">
        <img
          style="width: 120px; height: 120px"
          src="https://yinzhihui.oss-cn-beijing.aliyuncs.com/global/image/2024/10/42787c4b346db4f2f54b6d7c23000d11.jpg"
        />
      </div>
      <div class="content-info">
        <div class="info-row" style="color: #757575">咨询热线: 4001399335</div>
        <div class="info-row" style="color: #757575">
          咨询邮箱: yjl@w055.com.cn
        </div>
      </div>
    </div>
    <p style="width: 1200px; margin: auto; padding: 20px 0">
      © 2023-2024 北京银之荟科技有限公司 版权所有 盗版必究
      <img
        src="https://sh.yinzhih.com/data/attachment/global/image/2024/03/5dff8cdd1c4d49f25a91d1972d84b8c2.png"
      /><a href="https://beian.miit.gov.cn/#/Integrated/index" target="_self">
        京ICP2024053597号</a
      >
    </p>

    <el-dialog
      v-model="dialogVisible"
      width="900px"
      :show-close="false"
      @close="guanbi"
    >
      <el-dialog title="服务协议" v-model="fuwuxieyi" append-to-body>
        <div v-html="content"></div>
      </el-dialog>
      <div
        class="flex"
        style="
          background-color: #fff;
          width: 900px;
          height: 440px;
          background: #ffffff;
          border-radius: 15px;
        "
      >
        <div
          style="
            width: 289px;
            height: 440px;
            background: #17b26a;
            border-radius: 15px;
            box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
            text-align: center;
            color: #fff;
          "
        >
          <img
            style="
              width: auto;
              height: 66px;
              display: block;
              margin: 34px auto 9px;
            "
            :src="url + detail.image"
          />
          <div style="font-size: 21px; color: #fff">{{ detail.name }}</div>
          <div
            style="
              border-top: 1px solid #dcdcdc;
              border-bottom: 1px solid #dcdcdc;
              width: 220px;
              padding: 20px 0 26px;
              margin: auto;
              font-size: 16px;
              margin-top: 20px;
            "
          >
            <div
              class="flexJusp"
              style="align-items: center; margin-top: 14px"
              v-for="(item, index) in detail.content"
              :key="index"
            >
              <div>·{{ item }}</div>
              <img
                style="width: 16px; height: 16px; display: block"
                src="@/assets/images/dui_3.png"
              />
            </div>
          </div>
          <div style="font-size: 16px; color: #ffffff">
            服务费¥<span style="font-size: 32px; font-weight: bold">{{
              detail.price
            }}</span>
          </div>
        </div>
        <div v-if="!wancheng">
          <div>
            <div class="flexJusp" style="width: 588px; padding-top: 18px">
              <div></div>
              <img
                @click="guanbix"
                style="width: 32px; height: 32px; display: block"
                src="@/assets/images/guanbi.png"
              />
            </div>
            <div style="margin-left: 60px; width: 456px" v-if="!zhifu">
              <div
                class="flex"
                style="
                  align-items: center;
                  width: 456px;
                  height: 46px;
                  border-radius: 10px;
                  border: 1px solid #666666;
                  padding: 0 12px;
                  font-size: 18px;
                  margin: 18px 0 20px;
                "
              >
                <div class="flexJusp" style="width: 74px">
                  <span>账</span><span>号</span><span>名</span><span>称</span>
                </div>
                <input
                  v-model="account_name"
                  style="margin-left: 16px"
                  placeholder="请输入公众号或小程序名称"
                />
              </div>
              <div
                class="flex"
                style="
                  align-items: center;
                  width: 456px;
                  height: 46px;
                  border-radius: 10px;
                  border: 1px solid #666666;
                  padding: 0 12px;
                  font-size: 18px;
                  margin: 20px 0;
                "
              >
                <div class="flexJusp" style="width: 74px">
                  <span>公</span><span>司</span><span>名</span><span>称</span>
                </div>
                <input
                  v-model="company_name"
                  style="margin-left: 16px"
                  placeholder="请输入公司名称"
                />
              </div>
              <div
                class="flex"
                style="
                  align-items: center;
                  width: 456px;
                  height: 46px;
                  border-radius: 10px;
                  border: 1px solid #666666;
                  padding: 0 12px;
                  font-size: 18px;
                  margin: 20px 0;
                "
              >
                <div class="flexJusp" style="width: 74px">
                  <span>姓</span><span>名</span>
                </div>
                <input
                  v-model="real_name"
                  style="margin-left: 16px"
                  placeholder="请输入姓名"
                />
              </div>
              <div
                class="flex"
                style="
                  align-items: center;
                  width: 456px;
                  height: 46px;
                  border-radius: 10px;
                  border: 1px solid #666666;
                  padding: 0 12px;
                  font-size: 18px;
                  margin: ;
                "
              >
                <div class="flexJusp" style="width: 74px">
                  <span>手</span><span>机</span><span>号</span>
                </div>
                <input
                  v-model="user_phone"
                  style="margin-left: 16px"
                  placeholder="请输入手机号"
                />
              </div>
              <div
                class="flex"
                style="
                  justify-content: center;
                  align-items: center;
                  margin: 16px 0 24px;
                "
              >
                <img
                  v-if="xuanxie"
                  @click="xuanxie = !xuanxie"
                  style="width: 16px; height: 16px; display: block"
                  src="@/assets/images/dui_1.png"
                />
                <img
                  v-else
                  @click="xuanxie = !xuanxie"
                  style="width: 16px; height: 16px; display: block"
                  src="@/assets/images/weixuan.png"
                />
                <div style="font-size: 16px; color: #d7d7d7; margin-left: 8px">
                  我已阅读并同意和遵守
                  <span @click="fuwuxieyi = true" style="cursor: pointer"
                    >《服务协议》</span
                  >
                </div>
              </div>
              <div class="buttontan" @click="kaitong">立即开通</div>
            </div>
            <div
              style="margin-left: 60px; text-align: center; width: 540px"
              v-else
            >
              <div
                class="flex"
                style="
                  align-items: center;
                  justify-content: center;
                  margin: -2px 0 32px;
                "
              >
                <img
                  v-if="zhifus == 0"
                  style="
                    width: 20px;
                    height: 20px;
                    display: block;
                    cursor: pointer;
                  "
                  src="@/assets/images/xuan.png"
                />
                <img
                  v-else
                  @click="qiehuanx(0)"
                  style="
                    width: 20px;
                    height: 20px;
                    display: block;
                    cursor: pointer;
                  "
                  src="@/assets/images/weixuan.png"
                />
                <img
                  @click="qiehuanx(0)"
                  style="
                    width: 132px;
                    height: 30px;
                    display: block;
                    margin: 0 20px 0 12px;
                    cursor: pointer;
                  "
                  src="@/assets/images/pay_1.png"
                />

                <img
                  v-if="zhifus == 1"
                  style="
                    width: 20px;
                    height: 20px;
                    display: block;
                    cursor: pointer;
                  "
                  src="@/assets/images/xuan.png"
                />
                <img
                  v-else
                  @click="qiehuanx(1)"
                  style="
                    width: 20px;
                    height: 20px;
                    display: block;
                    cursor: pointer;
                  "
                  src="@/assets/images/weixuan.png"
                />
                <img
                  @click="qiehuanx(1)"
                  style="
                    width: 132px;
                    height: 30px;
                    display: block;
                    margin: 0 20px 0 12px;
                    cursor: pointer;
                  "
                  src="@/assets/images/pay_2.png"
                />
              </div>
              <div
                style="
                  width: 146px;
                  height: 146px;
                  border: 1px solid #a5a8a6;
                  margin: 32px auto 20px;
                "
              >
                <img
                  v-if="zhifus == 0"
                  :src="qrCode"
                  style="height: 145px; width: 145px"
                />
                <img
                  v-if="zhifus == 1"
                  :src="qrCodes"
                  style="height: 145px; width: 145px"
                />
              </div>
              <div v-if="zhifus == 0">微信扫码支付</div>
              <div v-if="zhifus == 1">支付宝支付</div>
              <div style="margin: 22px 0 12px">
                服务费¥<span
                  style="font-size: 32px; font-weight: bold; color: #17b26a"
                  >{{ detail.price }}</span
                >
              </div>
              <!-- dingdan -->
              <div class="flex" style="justify-content: center">
                <div
                  @click="fanhui"
                  style="
                    width: 220px;
                    height: 43px;
                    background: #a5a8a6;
                    border-radius: 10px;
                    font-size: 19px;
                    color: #ffffff;
                    line-height: 43px;
                    text-align: center;
                    cursor: pointer;
                  "
                >
                  返回
                </div>
                <div
                  @click="dingdan"
                  class="buttontan"
                  style="margin: 0 0 0 20px"
                >
                  支付完成
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-left: 60px; font-size: 18px; width: 540px" v-else>
          <div
            style="
              font-size: 22px;
              color: #000000;
              text-align: center;
              padding: -12px 0 55px;
            "
          >
            账号信息
          </div>
          <div class="flex">
            <div style="width: 80px" class="flexJusp">
              <span>账</span><span>号</span>
            </div>
            ：{{ account.account || "" }}
          </div>
          <div class="flex" style="margin: 10px 0">
            <div style="width: 80px" class="flexJusp">
              <span>密</span><span>码</span>
            </div>
            ：{{ account.password || "" }}
          </div>
          <div class="flex" style="align-items: center">
            <div style="width: 80px" class="flexJusp">
              <span>认</span><span>证</span><span>网</span><span>址</span>
            </div>
            <div>：{{ product.frontend_url }}</div>
            <img
              style="width: 18px; height: 18px; margin: 0 6px 0 12px"
              src="@/assets/images/num_1.png"
            />
            <div
              @click="renzheng"
              style="
                width: 58px;
                height: 21px;
                background: #17b26a;
                border-radius: 11px;
                font-size: 14px;
                color: #ffffff;
                line-height: 21px;
                text-align: center;
                cursor: pointer;
              "
            >
              去认证
            </div>
          </div>
          <div class="flex" style="align-items: center; margin: 10px 0">
            <div style="width: 80px" class="flexJusp">
              <span>管</span><span>理</span><span>后</span><span>台</span>
            </div>
            <div>：{{ product.backend_url }}</div>
            <img
              style="width: 18px; height: 18px; margin: 0 6px 0 12px"
              src="@/assets/images/num_2.png"
            />
            <div
              @click="guanli"
              style="
                width: 58px;
                height: 21px;
                background: #17b26a;
                border-radius: 11px;
                font-size: 14px;
                color: #ffffff;
                line-height: 21px;
                text-align: center;
                cursor: pointer;
              "
            >
              去管理
            </div>
          </div>
          <div>服务商联系方式：{{ product.kf_phone }}</div>
          <div
            style="margin: 45px auto 20px; text-align: center; font-size: 18px"
          >
            <span style="color: #ff0000">*</span> 请妥善保管账号信息以免丢失
          </div>
          <div class="buttontan" style="margin: auto" @click="fuzhi">
            一键复制
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      v-model="dialogVisibles"
      width="900px"
      :show-close="false"
      @close="guanbis"
    >
      <div
        class="flex"
        style="
          background-color: #fff;
          width: 900px;
          height: 440px;
          background: #ffffff;
          border-radius: 15px;
        "
      >
        <div
          style="
            width: 289px;
            height: 440px;
            background: linear-gradient(0deg, #484848 0%, #616060 100%);
            border-radius: 15px;
            box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
            text-align: center;
            color: #fff;
          "
        >
          <img
            style="
              width: auto;
              height: 66px;
              display: block;
              margin: 34px auto 9px;
              border-radius: 50%;
            "
            :src="url + detail.image"
          />
          <div style="font-size: 21px; color: #fff">{{ detail.name }}</div>
          <div
            style="
              border-top: 1px solid #dcdcdc;
              border-bottom: 1px solid #dcdcdc;
              width: 220px;
              padding: 34px 0 26px;
              margin: auto;
              font-size: 16px;
              margin-top: 30px;
            "
          >
            <div
              class="flexJusp"
              style="align-items: center; margin-top: 14px"
              v-for="(item, index) in detail.content"
              :key="index"
            >
              <div>·{{ item }}</div>
              <img
                style="width: 16px; height: 16px; display: block"
                src="@/assets/images/dui_3.png"
              />
            </div>
          </div>
          <div style="font-size: 16px; color: #ffffff; padding-top: 20px">
            高级定制服务
          </div>
        </div>
        <div>
          <div class="flexJusp" style="width: 588px; padding-top: 18px">
            <div></div>
            <img
              @click="guanbixx"
              style="width: 32px; height: 32px; display: block"
              src="@/assets/images/guanbi.png"
            />
          </div>
          <div style="margin-left: 60px; text-align: center; width: 540px">
            <div style="font-size: 35px; text-align: center; padding-top: 4px">
              微信扫码
            </div>
            <div style="font-size: 28px; padding-bottom: 35px">[联系专员]</div>
            <div
              style="
                width: 158px;
                height: 158px;
                background: #ffffff;
                border-radius: 15px;
                border: 1px solid #a5a8a6;
                margin: auto;
              "
            >
              <img
                style="width: 145px; height: 145px; margin: 6px auto"
                :src="url + detail.wx_qrcode"
              />
            </div>
            <div style="font-size: 17px; margin-top: 38px">
              联系热线：{{ detail.kf_phone }}
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import QRCode from "qrcode";
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      xuanxie: false,
      url: "https://tg.yinzhih.com",
      type: 2,
      types: 0,
      zhifus: 0,
      dialogVisible: false,
      list: [],
      detail: {},
      account_name: "",
      company_name: "",
      real_name: "",
      user_phone: "",
      zhifu: false,
      wancheng: false,
      qrCode: "",
      qrCodes: "",
      order_id: "",
      order_ids: "",
      dialogVisibles: false,
      account: {
        account: "",
        password: "",
      },
      product: {},
      fuwuxieyi: false,
      content: "",
      // pingDetail:{}
    };
  },
  mounted() {
    // 判断页面是否在手机端打开
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      window.location.href = "https://tg.yinzhih.com/h5/";
    } else {
      this.loads();
    }
  },
  methods: {
    shouye() {
      window.location.href = "https://www.yinzhih.com/";
    },
    hangye() {
      window.location.href = "https://www.yinzhih.com/?pid=13&tid=6";
    },
    yingyong() {
      window.location.href = "https://www.yinzhih.com/?pid=22&tid=6";
    },
    lianxi() {
      window.location.href = "https://www.yinzhih.com/?pid=33&tid=6";
    },
    denglu() {
      window.location.href = "https://www.yinzhih.com/account#/login";
    },
    guanbix() {
      this.dialogVisible = false;
    },
    guanbixx() {
      this.dialogVisibles = false;
    },
    renzheng() {
      window.location.href = this.product.frontend_url;
    },
    guanli() {
      window.location.href = this.product.backend_url;
    },
    fuzhi() {
      var text =
        "账号：" +
        this.account.account +
        "密码：" +
        this.account.password +
        "认证网址：" +
        this.product.frontend_url +
        "管理后台：" +
        this.product.backend_url +
        "服务商联系方式：" +
        this.product.kf_phone;
      navigator.clipboard.writeText(text);

      let input = document.createElement("input");
      document.body.appendChild(input);

      input.value = this.msg2; // 此处为需要复制的文本变量
      input.focus();
      input.select();
      try {
        let result = document.execCommand("copy");
        document.body.removeChild(input);
        if (!result) {
          console.error("复制失败");
        } else {
          this.$message.success("复制成功");
        }
      } catch (e) {
        document.body.removeChild(input);
        alert("当前浏览器不支持复制功能，请检查更新或更换其他浏览器操作");
      }
    },
    guanbi() {
      this.wancheng = false;
      this.zhifu = false;
      this.account_name = "";
      this.company_name = "";
      this.real_name = "";
      this.user_phone = "";
    },
    fanhui() {
      this.zhifu = false;
      this.account_name = "";
      this.company_name = "";
      this.real_name = "";
      this.user_phone = "";
    },
    qiehuanx(type) {
      if (type == 1) {
        if (this.qrCodes == "") {
          this.zhifubao();
        }
      }
      this.zhifus = type;
    },
    loads() {
      axios
        .get("https://tg.yinzhih.com/api/product/lst")
        .then((res) => {
          this.list = res.data.data;
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });

      // axios.get('https://tg.yinzhih.com/api/index/module')
      // .then(res => {
      //   this.pingDetail = res.data.data
      // })
      // .catch(error => {
      //   console.error('There was an error!', error);
      // });
      axios
        .get("https://tg.yinzhih.com/api/index/config?key=agreement")
        .then((res) => {
          this.content = res.data.data.text;
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
    weixin() {
      var pay_type = "wechat";
      const service = axios.create({
        baseURL: "https://tg.yinzhih.com/api/",
        timeout: 5000,
      });
      // 添加请求拦截器
      service.interceptors.request.use(
        (config) => {
          // 在发送请求之前设置header
          config.headers["device"] = "web"; // 例如设置Bearer Token
          return config;
        },
        (error) => {
          // 请求错误处理
          return Promise.reject(error);
        }
      );
      service
        .post("product/createOrder", {
          product_id: this.detail.id,
          account_name: this.account_name,
          company_name: this.company_name,
          real_name: this.real_name,
          user_phone: this.user_phone,
          pay_type: pay_type,
        })
        .then((response) => {
          if (response.data.code == 1) {
            this.zhifu = true;
            this.order_id = response.data.data.order_id;
            let opts = {
              errorCorrectionLevel: "H",
              type: "image/png",
              quality: 0.3,
              width: 145,
              heigth: 145,
            };
            QRCode.toDataURL(response.data.data.code_url, opts, (err, url) => {
              console.log(err, url);
              this.qrCode = url;
            });
          }

          // 处理响应数据
          console.log(response.data);
        })
        .catch((error) => {
          // 处理响应错误
          console.error(error);
        });
    },
    zhifubao() {
      var pay_type = "alipay";
      const service = axios.create({
        baseURL: "https://tg.yinzhih.com/api/",
        timeout: 5000,
      });
      // 添加请求拦截器
      service.interceptors.request.use(
        (config) => {
          // 在发送请求之前设置header
          config.headers["device"] = "web"; // 例如设置Bearer Token
          return config;
        },
        (error) => {
          // 请求错误处理
          return Promise.reject(error);
        }
      );
      service
        .post("product/orderPay", {
          order_id: this.order_id,
          pay_type: pay_type,
        })
        .then((response) => {
          this.zhifu = true;
          let opts = {
            errorCorrectionLevel: "H",
            type: "image/png",
            quality: 0.3,
            width: 145,
            heigth: 145,
          };
          QRCode.toDataURL(response.data.data.qr_code, opts, (err, url) => {
            console.log(err, url);
            this.qrCodes = url;
          });
          // 处理响应数据
          console.log(response.data);
        })
        .catch((error) => {
          // 处理响应错误
          console.error(error);
        });
    },
    kaitong() {
      if (!this.xuanxie) {
        this.$message.error("请阅读并同意和遵守服务协议后继续");
        return;
      }

      if (this.company_name == "") {
        this.$message.error("请输入公司名称后继续");
        return;
      }
      if (this.account_name == "") {
        this.$message.error("请输入账号名称后继续");
        return;
      }
      if (this.real_name == "") {
        this.$message.error("请输入姓名后继续");
        return;
      }
      if (this.user_phone == "") {
        this.$message.error("请输入手机号后继续");
        return;
      }

      var isMobile = /^(((1[3456789][0-9]{1})|(15[0-9]{1}))+\d{8})$/; //是否是手机号
      console.log(isMobile.test(this.user_phone), "----------");
      if (!isMobile.test(this.user_phone)) {
        this.$message.error("请输入正确的手机号号");
        return;
      }
      this.weixin();
    },
    dingdan() {
      // if(this.zhifus==0){
      axios
        .post("https://tg.yinzhih.com/api/product/query_order", {
          order_id: this.order_id,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message.error(res.data.msg);
            return;
          }
          if (res.data.data.account) {
            this.account = res.data.data.account;
          }
          this.product = res.data.data.product;
          this.wancheng = true;
          console.log(res, "微信支付");
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
      // }else{
      //   axios.post('https://tg.yinzhih.com/api/product/query_order',{
      //     order_id:this.order_id
      //   })
      //   .then(res => {
      //     if(res.data.data.account){
      //       this.account = res.data.data.account
      //     }
      //     this.product = res.data.data.product
      //     this.wancheng = true
      //     console.log(res,'r支付宝支付')
      //   })
      //   .catch(error => {
      //     console.error('There was an error!', error);
      //   });
      // }
    },
    qiehuan(type) {
      console.log(type);
      // this.type = type
    },
    qiehuans(type) {
      this.types = type;
    },
    open(item) {
      this.detail = item;
      if (item.type == 1) {
        this.dialogVisible = true;
      } else {
        axios
          .get("https://tg.yinzhih.com/api/product/detail?id=" + item.id)
          .then((res) => {
            this.detail = res.data.data;
            this.dialogVisibles = true;
            console.log(res.data.data, "服务信息");
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.flex {
  display: flex;
}
input {
  width: 320px;
  font-size: 18px;
  border: 0;
}
input:focus {
  border: 0;
  /* border-color: #40a9ff; */
  /* border-right-width: 1px !important; */
  /* outline: 0; */
  /* box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); */
}
input:focus {
  border: 0;
  /* border-color: #40a9ff; */
  /* border-right-width: 1px !important; */
  outline: 0;
  /* box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); */
}
.flexJusp {
  display: flex;
  justify-content: space-between;
}
.header {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  height: 80px;
  width: 100%;
  z-index: 9;
}
.xuan {
  font-size: 19px;
  color: #17b26a;
  border-bottom: 1px solid #17b26a;
}
.weixuan {
  font-size: 19px;
  color: #1a1a1a;
}
.buttons {
  width: 65px;
  height: 25px;
  background: #17b26a;
  border-radius: 13px;
  font-size: 15px;
  color: #ffffff;
  line-height: 25px;
  text-align: center;
  cursor: pointer;
}
.buttonx {
  margin: auto;
  cursor: pointer;
  width: 220px;
  height: 43px;
  border-radius: 10px;
  border: 1px solid #17b26a;
  font-size: 19px;
  color: #17b26a;
  line-height: 43px;
  text-align: center;
}
.buttontan {
  margin: auto;
  cursor: pointer;
  width: 220px;
  height: 43px;
  border-radius: 10px;
  background-color: #17b26a;
  border: 1px solid #17b26a;
  font-size: 19px;
  color: #ffffff;
  line-height: 43px;
  text-align: center;
}
.buttonx:active {
  cursor: pointer;
  width: 220px;
  height: 43px;
  border-radius: 10px;
  background-color: #17b26a;
  border: 1px solid #17b26a;
  font-size: 19px;
  color: #ffffff;
  line-height: 43px;
  text-align: center;
}
.buttonx:hover {
  cursor: pointer;
  width: 220px;
  height: 43px;
  border-radius: 10px;
  background-color: #17b26a;
  border: 1px solid #17b26a;
  font-size: 19px;
  color: #ffffff;
  line-height: 43px;
  text-align: center;
}
.buttonss {
  margin: auto;
  cursor: pointer;
  width: 220px;
  height: 43px;
  border-radius: 10px;
  background-color: #c9a786;
  border: 1px solid #c9a786;
  font-size: 19px;
  color: #ffffff;
  line-height: 43px;
  text-align: center;
}
.buttonxuan {
  cursor: pointer;
  background: #17b26a;
  width: 187px;
  height: 48px;
  border-radius: 10px;
  border: 1px solid #17b26a;
  font-size: 22px;
  color: #fff;
  line-height: 48px;
  text-align: center;
}
.buttonwei {
  cursor: pointer;
  width: 187px;
  height: 48px;
  border-radius: 10px;
  border: 1px solid #17b26a;
  font-size: 22px;
  color: #17b26a;
  line-height: 48px;
  text-align: center;
}
.el-pagination .btn-prev,
.el-pagination .btn-next {
  background-color: #f5f5f5; /* 举例：更改分页按钮的背景颜色 */
}

/deep/ .el-dialog {
  padding: 0;
  border-radius: 15px;
}
/deep/ .el-dialog__header {
  display: none;
  padding: 0;
}
.footer-content {
  display: flex;
  align-items: center;
}
.content-nav {
  flex: 1;
  display: flex;
  color: #d5d5d5;
  padding: 40px 40px 20px;
}
.nav-item {
  width: 220px;
}
.nav-item-title {
  font-size: 16px;
  font-weight: bold;
}
.content-qrcode {
  width: 130px;
}
.content-info {
  width: 300px;
  font-size: 15px;
}
.info-row {
  margin-top: 2px;
}
ul li {
  list-style: none; /* 去除列表项前的标记 */
  padding: 0; /* 去除列表项的内边距 */
  margin: 0; /* 去除列表项的外边距 */
}
a {
  color: inherit;
  /* 文字颜色继承父元素 */
  text-decoration: none;
  /* 去除下划线 */
  pointer-events: auto;
  /* 启用点击事件 */
  cursor: pointer;
  /* 将鼠标样式改为指针形状 */
}
</style>
