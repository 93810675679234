<template>
  <HelloWorld msg="Welcome to Your Vu.jss App"/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'


export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
*{
  padding:0;
  margin:0;
}
</style>
